import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { FieldValue } from '../../useValidateForm';
import { UserDataRequestBody, updateUserAccountData } from '../../../services/manageAccount';

import * as formConstants from '../../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../../utils/constants/i18nTranslationConstants';
import * as rowNameConstants from '../../../components/ProfileSettingsRow';

type useHandleModalArgs = {
  hasFormErrors: () => boolean;
  formErrors: FieldValue;
  resetPasswordFields: () => void;
  areSpecificFormFieldsValid: (fields: (keyof FieldValue)[]) => boolean;
  formValues: FieldValue;
  setFormValues: (values: FieldValue) => void;
  setPopUpMessage: React.Dispatch<React.SetStateAction<string>>;
  profileValues: FieldValue;
  setProfileValues: React.Dispatch<React.SetStateAction<FieldValue>>;
  bbid: string | undefined;
  accessToken: string | null;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPasswordNotCorrectError: () => void;
  setPopUpColor: React.Dispatch<React.SetStateAction<string>>;
  preferredLanguage: string;
  setPreferredLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export type DropdownOptions = {
  label: string;
  value: string;
};

const OLD_PASSWORD = 'oldPassword';
const BB_PREFERRED_LANGUAGE = 'bb_preferred_language';

export const useHandleModal = ({
  formValues,
  setFormValues,
  formErrors,
  hasFormErrors,
  setProfileValues,
  profileValues,
  resetPasswordFields,
  areSpecificFormFieldsValid,
  setPopUpMessage,
  setShowPopUp,
  bbid,
  accessToken,
  setCurrentPasswordNotCorrectError,
  setPopUpColor,
  preferredLanguage,
  setPreferredLanguage
}: useHandleModalArgs) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [selectedPreferredLanguageDropdownValue, setSelectedPreferredLanguageDropdownValue] =
    useState<string>(preferredLanguage);
  const [currentRow, setCurrentRow] = useState<string>('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  // Disable save button in modal if there are any form errors
  useEffect(() => {
    setIsSaveButtonDisabled(hasFormErrors);
  }, [formErrors]);

  useEffect(() => {
    setSelectedPreferredLanguageDropdownValue(preferredLanguage);
  }, [preferredLanguage]);

  const handlePreferredLanguageDropdownChange = (value: string) => {
    setSelectedPreferredLanguageDropdownValue(value);
    i18n.changeLanguage(value);
  };

  const handleChangeButtonClick = (rowName: string) => {
    setCurrentRow(rowName);
    setShowModal(true);
  };

  const handleCloseModal = (shouldUpdateProfileData = false) => {
    if (currentRow === rowNameConstants.PASSWORD_SETTINGS_ROW) {
      resetPasswordFields();
    }

    if (shouldUpdateProfileData) {
      setProfileValues(formValues);
    } else {
      if (currentRow === rowNameConstants.BB_PREFERRED_LANGUAGE_ROW) {
        i18n.changeLanguage(preferredLanguage);
        setSelectedPreferredLanguageDropdownValue(preferredLanguage);
      }
      setFormValues(profileValues);
    }

    setShowModal(false);
  };

  const getFieldsToCheck = (): (keyof FieldValue)[] => {
    switch (currentRow) {
      case rowNameConstants.NAME_SETTINGS_ROW:
        return [formConstants.FIRST_NAME_FIELD, formConstants.LAST_NAME_FIELD];
      case rowNameConstants.PASSWORD_SETTINGS_ROW:
        return [
          formConstants.CURRENT_PASSWORD_FIELD,
          formConstants.NEW_PASSWORD_FIELD,
          formConstants.NEW_CONFIRM_PASSWORD_FIELD
        ];
      case rowNameConstants.DISPLAY_NAME_SETTINGS_ROW:
        return [formConstants.DISPLAY_NAME_FIELD];
      case rowNameConstants.EMAIL_SETTINGS_ROW:
        return [formConstants.EMAIL_FIELD];
      default: // rows with no validation
        return [];
    }
  };

  const buildRequestBody = (): UserDataRequestBody => {
    const requestBody: UserDataRequestBody = {};

    switch (currentRow) {
      case rowNameConstants.NAME_SETTINGS_ROW:
        requestBody[formConstants.FIRST_NAME_FIELD] = formValues.firstName;
        requestBody[formConstants.LAST_NAME_FIELD] = formValues.lastName;
        setPopUpMessage(t(translationConstants.NAME_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.PASSWORD_SETTINGS_ROW:
        requestBody[formConstants.PASSWORD_FIELD] = formValues.newPassword;
        requestBody[OLD_PASSWORD] = formValues.currentPassword;
        setPopUpMessage(t(translationConstants.PASSWORD_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.EMAIL_SETTINGS_ROW:
        requestBody[formConstants.EMAIL_FIELD] = formValues.email;
        setPopUpMessage(
          t(translationConstants.VERIFICATION_EMAIL_SENT_PLEASE_VERIFY, {
            where: formValues.email
          })
        );
        break;
      case rowNameConstants.DISPLAY_NAME_SETTINGS_ROW:
        requestBody[formConstants.DISPLAY_NAME_FIELD] = formValues.displayName;
        setPopUpMessage(t(translationConstants.DISPLAY_NAME_CHANGED_SUCCESSFULLY));
        break;
      case rowNameConstants.BB_PREFERRED_LANGUAGE_ROW:
        requestBody[BB_PREFERRED_LANGUAGE] = selectedPreferredLanguageDropdownValue;
        setPopUpMessage(t(translationConstants.PREFERRED_LANGUAGE_SUCCESSFULLY_CHANGED));
        break;
      default:
        navigate('/error');
    }

    return requestBody;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const areFormFieldsValid = areSpecificFormFieldsValid(getFieldsToCheck());
    setIsSaveButtonDisabled(areFormFieldsValid);

    if (areFormFieldsValid) {
      setIsSaveButtonLoading(true);
      const requestBody = buildRequestBody();

      try {
        handleUpdateUserData(requestBody);
      } catch (error) {
        navigate('/error');
      }
    }
  };

  const handleUpdateUserData = async (requestBody: UserDataRequestBody) => {
    const response = await updateUserAccountData(bbid!, accessToken!, requestBody);
    const shouldUpdateProfileData = currentRow !== rowNameConstants.EMAIL_SETTINGS_ROW;

    if (response.ok) {
      handleCloseModal(shouldUpdateProfileData);

      if (currentRow === rowNameConstants.BB_PREFERRED_LANGUAGE_ROW) {
        setPreferredLanguage(selectedPreferredLanguageDropdownValue);
        await i18n.changeLanguage(selectedPreferredLanguageDropdownValue);
      }

      setIsSaveButtonDisabled(false);
      setIsSaveButtonLoading(false);

      setPopUpColor('bg-green-100');

      setShowPopUp(true);
    } else if (response.status === 403) {
      setCurrentPasswordNotCorrectError();
    } else if (response.status === 409) {
      setPopUpMessage(t(translationConstants.EMAIL_ALREADY_EXISTS, { where: formValues.email }));
      handleCloseModal(false);

      setIsSaveButtonDisabled(false);
      setIsSaveButtonLoading(false);

      setPopUpColor('bg-red-100');
      setShowPopUp(true);
    } else {
      navigate('/error');
    }
  };

  return {
    showModal,
    handleChangeButtonClick,
    handleSubmit,
    handleCloseModal,
    isSaveButtonDisabled,
    isSaveButtonLoading,
    currentRow,
    selectedPreferredLanguageDropdownValue,
    handlePreferredLanguageDropdownChange
  };
};
