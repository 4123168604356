import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DropdownOptions } from '../../hooks/manageAccount/useHandleModal';
import { FieldValue } from '../../hooks/useValidateForm';
import { ValidationError } from '../../components/ValidationError';
import { DropdownField } from '../../components/DropdownField';
import { FormInput } from '../../components/FormInput';
import { FormLabel } from '../../components/FormLabel';
import { FormTitle } from './FormTitle';

import * as formConstants from '../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';
import * as rowNameConstants from '../../components/ProfileSettingsRow';

type RenderModalContentProps = {
  currentRow: string;
  formValues: FieldValue;
  formErrors: FieldValue;
  handleChange: (fieldName: keyof FieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  preferredLanguageDropDownOptions: DropdownOptions[];
  selectedPreferredDropDownValue: string;
  handlePreferredLanguageDropdownChange: (value: string) => void;
};

export const RenderModalContent: FC<RenderModalContentProps> = ({
  currentRow,
  formValues,
  formErrors,
  handleChange,
  preferredLanguageDropDownOptions,
  selectedPreferredDropDownValue,
  handlePreferredLanguageDropdownChange
}) => {
  const { t } = useTranslation();

  switch (currentRow) {
    case rowNameConstants.NAME_SETTINGS_ROW:
      return (
        <>
          <FormTitle title={t(translationConstants.NAME)} />
          <div className="flex flex-col mb-3">
            <FormLabel text={t(translationConstants.FIRST_NAME)} />
            <FormInput
              placeholder={t(translationConstants.FIRST_NAME_EXAMPLE)}
              value={formValues.firstName}
              onChange={handleChange(formConstants.FIRST_NAME_FIELD)}
              hasError={formErrors.firstName != ''}
            />
            <ValidationError hidden={!formErrors.firstName} text={formErrors.firstName} />
          </div>
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.LAST_NAME)} />
            <FormInput
              placeholder={t(translationConstants.LAST_NAME_EXAMPLE)}
              value={formValues.lastName}
              onChange={handleChange(formConstants.LAST_NAME_FIELD)}
              hasError={formErrors.lastName != ''}
            />
            <ValidationError hidden={!formErrors.lastName} text={formErrors.lastName} />
          </div>
        </>
      );
    case rowNameConstants.DISPLAY_NAME_SETTINGS_ROW:
      return (
        <>
          <FormTitle
            title={t(translationConstants.DISPLAY_NAME_NO_BLUEBEAM)}
            testId={testConstants.MODAL_DISPLAY_NAME_TITLE}
          />
          <FormInput
            placeholder={t(translationConstants.DISPLAY_NAME_EXAMPLE)}
            value={formValues.displayName}
            onChange={handleChange(formConstants.DISPLAY_NAME_FIELD)}
            hasError={formErrors.displayName != ''}
          />
          <ValidationError hidden={!formErrors.displayName} text={formErrors.displayName} />
        </>
      );
    case rowNameConstants.EMAIL_SETTINGS_ROW:
      return (
        <>
          <FormTitle
            title={t(translationConstants.EMAIL)}
            testId={testConstants.MODAL_EMAIL_TITLE}
          />
          <p className="mb-5 font-[400] font-roboto text-[14px] leading-2">
            {t(translationConstants.WE_WILL_SEND_YOU_CONFIRMATION_EMAIL)}
          </p>
          <FormInput
            placeholder={t(translationConstants.EMAIL_EXAMPLE)}
            value={formValues.email}
            onChange={handleChange(formConstants.EMAIL_FIELD)}
            hasError={formErrors.email != ''}
          />
          <ValidationError hidden={!formErrors.email} text={formErrors.email} />
        </>
      );
    case rowNameConstants.PASSWORD_SETTINGS_ROW:
      return (
        <>
          <FormTitle
            title={t(translationConstants.PASSWORD)}
            testId={testConstants.MODAL_PASSWORD_TITLE}
          />
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.CURRENT_PASSWORD)} />
            <FormInput
              type="password"
              value={formValues.currentPassword}
              onChange={handleChange(formConstants.CURRENT_PASSWORD_FIELD)}
              hasError={formErrors.currentPassword != ''}
              testId={testConstants.CURRENT_PASSWORD_INPUT_FIELD}
            />
            <ValidationError
              hidden={!formErrors.currentPassword}
              text={formErrors.currentPassword}
            />
          </div>
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.NEW_PASSWORD)} />
            <FormInput
              type="password"
              value={formValues.newPassword}
              onChange={handleChange(formConstants.NEW_PASSWORD_FIELD)}
              hasError={formErrors.newPassword != ''}
              testId={testConstants.NEW_PASSWORD_INPUT_FIELD}
            />
            <ValidationError hidden={!formErrors.newPassword} text={formErrors.newPassword} />
          </div>
          <span>
            <ValidationError hidden={!formErrors.password} text={formErrors.password} />
            <span className="text-[14px] text-light-gray font-[500] leading-5">
              <Trans
                i18nKey={translationConstants.PASSWORD_REQUIREMENT_HTML}
                components={{
                  1: <span className="font-bold" />
                }}
              />
            </span>
          </span>
          <div className="mt-3 flex flex-col">
            <FormLabel text={t(translationConstants.CONFIRM_NEW_PASSWORD)} />
            <FormInput
              type="password"
              value={formValues.newConfirmPassword}
              onChange={handleChange(formConstants.NEW_CONFIRM_PASSWORD_FIELD)}
              hasError={formErrors.newConfirmPassword != ''}
              testId={testConstants.NEW_CONFIRM_PASSWORD_INPUT_FIELD}
            />
            <ValidationError
              hidden={!formErrors.newConfirmPassword}
              text={formErrors.newConfirmPassword}
            />
          </div>
        </>
      );
    case rowNameConstants.BB_PREFERRED_LANGUAGE_ROW:
      return (
        <>
          <FormTitle title="Language" testId={testConstants.MODAL_PREFERRED_LANGUAGE_TITLE} />
          <p className="font-[400] font-roboto text-[14px] leading-2">
            {t(translationConstants.CHANGES_TO_PREFERRED_LANGUAGE_IN_ALL_BB_CLOUD)}
          </p>
          <div className="flex flex-col mt-[20px] mb-2">
            <FormLabel text={t(translationConstants.PREFERRED_LANGUAGE)} />
            <DropdownField
              options={preferredLanguageDropDownOptions}
              value={selectedPreferredDropDownValue}
              onChange={handlePreferredLanguageDropdownChange}
              className="w-full mt-[5px]"
            />
          </div>
        </>
      );

    default:
      return null;
  }
};
